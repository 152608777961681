<template>
  <section
    :class="{ 'nft-grid--dense': (size === 'small') }"
    class="nft-grid"
  >
    <template v-if="loading">
    <!-- Need to change array size to original after mutated-->
      <v-skeleton-loader
        v-for="n in nfts"
        :key="`nft-list--skeleton-loader-${n.publicID}`"
        class="nft-skeleton-loader"
        outlined
        type="image, list-item-two-line, divider, actions"
      />
    </template>
    <nft-card
      v-for="nft in nfts"
      v-else
      :key="`marketplace-item-${nft.publicID}`"
      :imageHeight="size === 'small' ? 150 : 200"
      v-bind="convert(nft)"
    />
  </section>
</template>

<script>
import BigNumber from 'bignumber.js';

export default {
  name: 'NFTList',
  props: {
    nfts: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    loading: {
      type: Boolean,
      required: true,
      default: () => (false),
    },
    size: {
      type: String,
      required: false,
      default: () => ('large'),
      validator: (val) => ['small', 'large'].includes(val),
    },
  },
  methods: {
    convert(nft) {
      let calculatedVal = BigNumber(nft.value);
      calculatedVal = calculatedVal.div(1000000000); // convert to eth
      return {
        publicID: nft.publicID,
        contentType: nft.contentType,
        contentURL: nft.fileURL,
        mimeType: nft.mimeType,
        itemName: nft.itemName,
        likeCount: nft.likes,
        liked: nft.liked,
        onSale: nft.onSale,
        previewURL: nft.thumbnailURL, // will need to fix this part in order to be more consistent
        symbol: nft.currency,
        contractAddress: nft.contractAddress,
        value: calculatedVal.toString(),
        likeable: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.nft-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 30px;

  &--dense {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
</style>
